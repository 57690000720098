<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getDataReport"
  :isLoading="isLoad"
  isHiddenTableSearch
  :total="items.statuses_with_sums"
  isHiddenPageRow
  isHiddenPageCount
  isNotMounted
  :saveReport="saveReport")
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { headerStatisticTable } from './constants'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'statistics-user-verification',
  props: {
    method: String
  },
  data () {
    return {
      headers: headerStatisticTable(),
      defaultFilter: { page: '1', page_size: '10' }
    }
  },
  computed: {
    ...mapState({
      items: state => state.statistics.itemsVerification,
      isLoad: state => state.statistics.isLoad
    })
  },
  watch: {
    '$route.name' (newVal, oldVal) {
      if (newVal !== oldVal) this.getData()
    },
    '$route.query' (newVal, oldVal) {
      if (Object.keys(newVal).length && newVal !== oldVal) this.defaultFilter.page_size = +newVal.page_size
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapMutations(['SET_STATE_DATA_STATISTICS']),
    ...mapActions([
      'getUserVerification', 'getExistingDocVerification',
      'getSailorPhotoVerification', 'getEducationVerification', 'getMedicalCertificateVerification',
      'getQualificationVerification', 'getNavigatorVerification', 'getForeignPassportVerification',
      'getSailorPassportVerification', 'getServiceRecordVerification', 'getLineInServiceRecordVerification',
      'getSailorCitizenPassportVerification', 'getStatisticExel', 'getUserISCVerification']),
    getData () {
      this.SET_STATE_DATA_STATISTICS({
        isLoad: false,
        itemsVerification: {}
      })
    },
    getDataReport (filter) {
      let cloneFilter = { ...filter }
      delete cloneFilter.page
      delete cloneFilter.page_size
      if (Object.keys(cloneFilter).length) {
        this[this.currentMethod(this.$route.name).method](filter)
      } else {
        if (Object.keys(this.items).length) {
          this.getData()
        } else this.$notification.info('notify.info.choose filter')
      }
    },

    async saveReport (params) {
      const response = await this.getStatisticExel({ params, nameLink: this.currentMethod(this.$route.name).nameLink })
      if (SUCCESS_CODE.includes(response?.code)) this.$notification.success('excelStatement')
    },

    currentMethod (nameRoute) {
      switch (nameRoute) {
        case 'user-verification-statistics': return { method: 'getUserVerification', nameLink: 'userVerification' }
        case 'existing-doc-verification-statistics': return { method: 'getExistingDocVerification', nameLink: 'existingDocVerification' }
        case 'sailor-photo-verification-statistics': return { method: 'getSailorPhotoVerification', nameLink: 'sailorPhotoVerification' }
        case 'service-record-verification-statistics': return { method: 'getServiceRecordVerification' }
        case 'line-in-service-record-verification-statistics': return { method: 'getLineInServiceRecordVerification' }
        case 'education-verification-statistics': return { method: 'getEducationVerification' }
        case 'medical-certificate-verification-statistics': return { method: 'getMedicalCertificateVerification' }
        case 'qualification-verification-statistics': return { method: 'getQualificationVerification' }
        case 'navigator-verification-statistics': return { method: 'getNavigatorVerification' }
        case 'foreign-passport-verification-statistics': return { method: 'getForeignPassportVerification', nameLink: 'foreignPassportVerification' }
        case 'sailor-passport-verification-statistics': return { method: 'getSailorPassportVerification', nameLink: 'sailorPassportVerification' }
        case 'statistics-citizen-passport-verification': return { method: 'getSailorCitizenPassportVerification', nameLink: 'sailorCitizenPassportVerification' }
        case 'statistics-isc-user-verification': return { method: 'getUserISCVerification', nameLink: 'userISCVerification' }
        default: return 'getUserVerification'
      }
    }
  }
}
</script>
